import * as React from "react"
import { Helmet } from "react-helmet"

import Container from "../common/helpers/Container.js"
import ContainerWithBg from "../common/helpers/ContainerWithBg.js"

import Page from "../common/elements/Page.js"
import Button from "../common/components/Button.js"

const NotFoundPage = () => {
  return (
    <Page>
      <Helmet>
        <link rel="preload" as="image" href="../images/bg-404-img.png"></link>
      </Helmet>
      <ContainerWithBg
          classBgName="bg-pattern bg-pattern-linear-darker bg-image-404-hero"
          className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1"
        >
          <span className="tag bg-color-golden c-purple m-2 c-p db ttu mb-2 bold">
            404
          </span>
          <h1 className="h1 mb-15r">Ops, página não encontrada</h1>
          
        </ContainerWithBg>
        <Container className="container py-6">
        <h3 className="mb-4 tac line-lenght-medium m-auto c-purple">
          O conteúdo que você está procurando não está aqui. Algumas destas páginas podem te ajudar:
        </h3>        
        <ul className="list-default list-unstyled not-found-link-list">
          <li><Button to="/" className="">Página inicial</Button></li>
          <li><Button to="consultoria/" className="">Consultoria</Button></li>
          <li><Button to="cursos/" className="">Cursos</Button></li>
          <li><Button to="workshops/" className="">Workshops</Button></li>
          <li><Button to="cases/" className="">Cases</Button></li>
          <li><Button to="blog/" className="">Blog</Button></li>
          <li><Button to="materiais-educativos" className="">Materiais educativos</Button></li>
          <li><Button to="quem-somos/" className="">Sobre a Comunicação Integrada</Button></li>
          <li><Button to="contato/" className="">Contato</Button></li>
        </ul>
        </Container>
    </Page>
  )
}

export default NotFoundPage
